var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "d-flex" }, [
    _c("div", { staticClass: "status-column", class: _vm.statusColor }, [
      _vm._v(" " + _vm._s(_vm.statusText) + " "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }