var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "Dialog",
        {
          style: { maxHeight: "800px", minWidth: "600px" },
          attrs: { visible: _vm.displayEnableModal, modal: true },
          on: {
            "update:visible": function ($event) {
              _vm.displayEnableModal = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_vm._v(" " + _vm._s(_vm.titleHeader) + " ")]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("Button", {
                    ref: "btnClose",
                    staticClass: "outlined",
                    attrs: { label: _vm.$t("cancel") },
                    on: {
                      click: function ($event) {
                        _vm.displayEnableModal = false
                      },
                    },
                  }),
                  _c("Button", {
                    ref: "btnSubmit",
                    staticClass: "p-button-raised mr-0",
                    attrs: { label: _vm.$t("confirm"), autofocus: "" },
                    on: {
                      click: function ($event) {
                        return _vm.enableUser()
                      },
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
          _c("div", { staticClass: "grip grid-cols-2 pb-2 px-2 py-2" }, [
            _c("div", { staticClass: "col-span-1" }, [
              _c("span", { staticClass: "text-grey label" }, [
                _vm._v(" " + _vm._s(_vm.$t("users.usersTable.user")) + " "),
              ]),
              _c("div", [_vm._v(" " + _vm._s(_vm.userName) + " ")]),
            ]),
            _c("div", [
              _c(
                "div",
                [
                  _c("span", { staticClass: "text-grey label" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("users.usersTable.profile")) + " "
                    ),
                  ]),
                  _c("input-validation", {
                    attrs: { rules: "required" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("MultiSelect", {
                              class: { "p-invalid": errors[0], "w-full": true },
                              attrs: {
                                "input-id": "profile",
                                options: _vm.profiles,
                                "data-key": "",
                                "option-label": "optionLabel",
                                "option-value": "optionValue",
                                placeholder: _vm.$t(
                                  "users.userForm.fields.profile.placeholder"
                                ),
                                display: "chip",
                              },
                              model: {
                                value: _vm.selectedProfiles,
                                callback: function ($$v) {
                                  _vm.selectedProfiles = $$v
                                },
                                expression: "selectedProfiles",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }