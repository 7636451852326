var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("header-section", {
        on: {
          "users:register-users": _vm.showModal,
          "users:search": function ($event) {
            return _vm.searchUser($event)
          },
        },
      }),
      _c("content-section", {
        attrs: { "register-user": _vm.displayModal, search: _vm.search },
        on: {
          "update:registerUser": function ($event) {
            _vm.displayModal = $event
          },
          "update:register-user": function ($event) {
            _vm.displayModal = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }