import { LocaleMessages } from 'vue-i18n';

const locales: LocaleMessages = {
  'pt-br': {
    users: {
      newRegister: 'Cadastrar Usuários',
      companySelectLabel: 'Selecione uma empresa para vincular ao usuário',
      companySelect: 'Selecione uma empresa',
      usersTable: {
        user: 'Usuário',
        occupation: 'Cargo',
        company: 'Empresa',
        mail: 'E-mail',
        status: 'Status',
        profile: 'Perfil',
        allowedAccessTypes: 'Tipos de Acesso Permitidos',
        redefineMfa: {
          title: 'Redefinir Dupla Autenticação',
          subTitle: 'Redefinindo dupla autenticação do e-mail:',
          description: 'Você tem certeza que deseja redefinir a dupla autenticação, pois ao realizar este procedimento, o usuário será obrigado a realizar um novo cadastro de dupla autenticação',
          success: 'Dupla autenticação redefinida com sucesso.',
          fail: 'Ocorreu um problema, tente novamente.',
        },
      },
      userForm: {
        fields: {
          name: {
            label: 'Nome',
            placeholder: 'Ex: Marcelo',
          },
          surname: {
            label: 'Sobrenome',
            placeholder: 'Ex: Silva',
          },
          cellphone: {
            label: 'Telefone',
            placeholder: 'Ex: (99) 99999-9999',
          },
          mail: {
            label: 'E-mail',
            placeholder: 'Ex: nome@suaempresa.com',
          },
          occupation: {
            label: 'Cargo',
            placeholder: 'Ex: Analista Fiscal',
          },
          company: {
            label: 'Vincular empresa ao contato',
            placeholder: 'Selecione uma empresa',
          },
          profile: {
            label: 'Perfil',
            placeholder: 'Selecione os perfis',
          },
          responsibilities: {
            label: 'Áreas de responsabilidade',
            placeholder: 'Selecione',
          },
          allowedAccessTypes: {
            label: 'Permitir acesso com',
            placeholder: 'Selecione',
          },
        },
      },
      userImport: {
        importMessage: 'Para importar contatos, selecione um perfil, baixe o template padrão correspondente, preencha-o e arraste para o campo abaixo',
        dropzone: 'Arraste o template aqui ou clique para selecioná-lo.',
        dropzoneEmpty: 'Nenhum template adicionado para importação',
      },
      userFormModal: {
        tabRegisterUser: 'Cadastrar usuário',
        titleRegister: 'Cadastrar Usuários',
        titleUpdate: 'Atualizar Usuário',
      },
      list: {
        occupations: {
          msgError: 'Não foi possível obter as opções para ocupações',
        },
        products: {
          msgError: 'Não foi possível obter as opções para produtos',
        },
        establishment: {
          msgError: 'Não foi possível obter as opções para empresas',
        },
        responsibilityArea: {
          msgError: 'Não foi possível obter as opções para areas de responsabilidade',
        },
      },
      actions: {
        findUsers: {
          msgError: 'Não foi possível buscar os usuários',
          msgWarning: 'Uma empresa deve ser selecionada para buscar seus usuários',
        },
        delete: {
          one: {
            title: 'Excluir usuário',
            message: 'Confirma a exclusão deste usuário?',
            msgError: 'Não foi possível excluir o usuário',
          },
          many: {
            title: 'Excluir usuários',
            message: 'Confirma a exclusão destes usuários?',
            msgError: 'Não foi possível excluir os usuários',
          },
        },
        save: {
          msgSuccess: 'Usuário registrado com sucesso',
          msgError: 'Não foi possível registrar o usuário',
        },
        update: {
          msgSuccess: 'Dados do usuário atualizado com sucesso',
          msgError: 'Não foi possível atualizar os dados do usuário',
        },
        importUser: {
          msgSuccess: 'Usuários registrados com sucesso',
          msgError: 'Não foi possível registrar os usuários',
        },
      },
      accessTypes: {
        loginPass: {
          label: 'Login e Senha',
        },
        sso: {
          label: 'SSO',
        },
      },
    },
  },
  es: {
    users: {
      newRegister: 'Registrar usuarios',
      companySelectLabel: 'Seleccione una empresa para vincular al usuario',
      companySelect: 'Seleccionar una empresa',
      userTable: {
        user: 'Usuario',
        occupation: 'Puesto de trabajo',
        company: 'Empresa',
        mail: 'Correo electrónico',
        status: 'Status',
        profile: 'Perfil',
        allowedAccessTypes: 'Tipos de Acceso Permitidos',
        redefineMfa: {
          title: 'Restablecer autenticación dual',
          subTitle: 'Restablecimiento de la autenticación doble de correo electrónico:',
          description: '¿Está seguro de que desea restablecer la doble autenticación, ya que al realizar este procedimiento, el usuario se verá obligado a realizar un nuevo registro de doble autenticación',
          success: 'Restablecimiento de doble autenticación con éxito.',
          fail: 'Hubo un problema, inténtalo de nuevo.',
        },
      },
      userForm: {
        fields: {
          name: {
            label: 'Nombre',
            placeholder: 'Ex: Marcelo',
          },
          surname: {
            label: 'Apellido',
            placeholder: 'Ex: Silva',
          },
          cellphone: {
            label: 'Teléfono',
            placeholder: 'Ex: (99) 99999-9999',
          },
          mail: {
            label: 'Correo electrónico',
            placeholder: 'Ejemplo: nombre@suempresa.com',
          },
          occupation: {
            label: 'Posición',
            placeholder: 'Ex: Analista fiscal',
          },
          company: {
            label: 'Vincular empresa a contacto',
            placeholder: 'Seleccionar una empresa',
          },
          profile: {
            label: 'Perfil',
            placeholder: 'Seleccionar perfiles',
          },
          responsibilities: {
            label: 'Áreas de responsabilidad',
            placeholder: 'Seleccionar',
          },
          allowedAccessTypes: {
            label: 'Permitir el acceso con',
            placeholder: 'Seleccionar',
          },
        },
      },
      userImport: {
        importMessage: 'Para importar contactos, seleccione un perfil, descargue la plantilla predeterminada correspondiente, rellénela y arrástrela al campo de abajo',
        dropzone: 'Arrastre la plantilla aquí o haga clic para seleccionarla.',
        dropzoneEmpty: 'No se agregaron plantillas para importar',
      },
      userFormModal: {
        tabRegisterUser: 'Registrar usuario',
        tabImportUser: 'Importar usuario (lote)',
        titleRegister: 'Registrar usuarios',
        titleUpdate: 'Actualizar usuario',
        btns: {
          importLabel: 'Importar usuarios',
        },
      },
      list: {
        occupations: {
          msgError: 'No se pudieron obtener opciones para las ocupaciones',
        },
        products: {
          msgError: 'No se pudieron obtener opciones para los productos',
        },
        establishment: {
          msgError: 'No se pudieron obtener opciones para las empresas',
        },
        responsibilityArea: {
          msgError: 'No se pueden obtener opciones para las áreas de responsabilidad',
        },
      },
      actions: {
        findUsers: {
          msgError: 'No se pueden recuperar usuarios',
          msgWarning: 'Se debe seleccionar una empresa para buscar sus usuarios',
        },
        delete: {
          one: {
            title: 'Eliminar usuario',
            message: '¿Confirmar la eliminación de este usuario?',
            msgError: 'No se puede eliminar al usuario',
          },
          many: {
            title: 'Eliminar usuarios',
            message: '¿Confirmar la eliminación de estos usuarios?',
            msgError: 'No se pueden eliminar usuarios',
          },
        },
        save: {
          msgSuccess: 'Usuario registrado correctamente',
          msgError: 'No se puede registrar al usuario',
        },
        update: {
          msgSuccess: 'Datos de usuario actualizados correctamente',
          msgError: 'No se pueden actualizar los datos del usuario',
        },
        importUser: {
          msgSuccess: 'Usuarios registrados correctamente',
          msgError: 'No se pueden registrar usuarios',
        },
      },
      accessTypes: {
        loginPass: {
          label: 'Usuario y Contraseña',
        },
        sso: {
          label: 'SSO',
        },
      },
    },
  },
  en: {
    users: {
      newRegister: 'Register Users',
      companySelectLabel: 'Select a company to link to the user',
      companySelect: 'Select a company',
      userTable: {
        user: 'User',
        occupation: 'Position',
        company: 'Company',
        email: 'Email',
        status: 'Status',
        profile: 'Profile',
        allowedAccessTypes: 'Allowed Access Types',
        redefineMfa: {
          title: 'Reset Dual Authentication',
          subTitle: 'Resetting email double authentication:',
          description: 'Are you sure you want to reset double authentication, because when performing this procedure, the user will be forced to perform a new double authentication registration',
          success: 'Dual authentication reset successfully.',
          fail: 'There was a problem, please try again.',
        },
      },
      userForm: {
        fields: {
          name: {
            label: 'Name',
            placeholder: 'Ex: Marcelo',
          },
          surname: {
            label: 'Last name',
            placeholder: 'Ex: Silva',
          },
          cellphone: {
            label: 'Phone',
            placeholder: 'Ex: (99) 99999-9999',
          },
          mail: {
            label: 'Email',
            placeholder: 'Ex: name@yourcompany.com',
          },
          occupation: {
            label: 'Position',
            placeholder: 'Ex: Tax Analyst',
          },
          company: {
            label: 'Link company to contact',
            placeholder: 'Select a company',
          },
          profile: {
            label: 'Profile',
            placeholder: 'Select profiles',
          },
          responsibilities: {
            label: 'Areas of responsibility',
            placeholder: 'Select',
          },
          allowedAccessTypes: {
            label: 'Allow access with',
            placeholder: 'Select',
          },
        },
      },
      userImport: {
        importMessage: 'To import contacts, select a profile, download the corresponding default template, fill it and drag to the field below',
        dropzone: 'Drag the template here or click to select it.',
        dropzoneEmpty: 'No templates added for import',
      },
      userFormModal: {
        tabRegisterUser: 'Register user',
        tabImportUser: 'Import User (batch)',
        titleRegister: 'Register Users',
        titleUpdate: 'Update User',
        btns: {
          importLabel: 'Import Users',
        },
      },
      list: {
        occupations: {
          msgError: 'Could not get options for occupations',
        },
        products: {
          msgError: 'Could not get options for products',
        },
        establishment: {
          msgError: 'Could not get options for companies',
        },
        responsibilityArea: {
          msgError: 'Unable to get options for areas of responsibility',
        },
      },
      actions: {
        findUsers: {
          msgError: 'Unable to fetch users',
          msgWarning: 'A company must be selected to search its users',
        },
        delete: {
          one: {
            title: 'Delete user',
            message: 'Confirm the deletion of this user?',
            msgError: 'Unable to delete user',
          },
          many: {
            title: 'Delete users',
            message: 'Confirm the deletion of these users?',
            msgError: 'Unable to delete users',
          },
        },
        save: {
          msgSuccess: 'User registered successfully',
          msgError: 'Unable to register user',
        },
        update: {
          msgSuccess: 'User data updated successfully',
          msgError: 'Unable to update user data',
        },
        importUser: {
          msgSuccess: 'Users successfully registered',
          msgError: 'Unable to register users',
        },
      },
      accessTypes: {
        loginPass: {
          label: 'Login and Password',
        },
        sso: {
          label: 'SSO',
        },
      },
    },
  },
};

export default locales;
