import { render, staticRenderFns } from "./HeaderSection.vue?vue&type=template&id=3ce54201&scoped=true&"
import script from "./HeaderSection.vue?vue&type=script&lang=ts&"
export * from "./HeaderSection.vue?vue&type=script&lang=ts&"
import style0 from "./HeaderSection.vue?vue&type=style&index=0&id=3ce54201&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ce54201",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/roit-company-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3ce54201')) {
      api.createRecord('3ce54201', component.options)
    } else {
      api.reload('3ce54201', component.options)
    }
    module.hot.accept("./HeaderSection.vue?vue&type=template&id=3ce54201&scoped=true&", function () {
      api.rerender('3ce54201', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/resources/users/sections/HeaderSection.vue"
export default component.exports