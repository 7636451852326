
import mixins from 'vue-typed-mixins';
import { mapMutations } from 'vuex';
import { Dropzone } from 'vue2-dropzone';
import NotificationMixin from '@/mixins/NotificationMixin';
import ConvertFile from '@/common/classes/ConvertFile';
import locales from '../locales';

export default mixins(NotificationMixin).extend({
  name: 'UserImport',

  i18n: {
    messages: locales,
  },

  data: () => ({
    urlTemplate:
      'https://api.media.atlassian.com/file/712e8294-98d8-4b7a-aab5-216266455e07/binary?client=41f70cfd-bff2-4c8a-9240-efc71ebe28ea&collection=contentId-582943311&dl=true&max-age=2592000&token=eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiI0MWY3MGNmZC1iZmYyLTRjOGEtOTI0MC1lZmM3MWViZTI4ZWEiLCJhY2Nlc3MiOnsidXJuOmZpbGVzdG9yZTpjb2xsZWN0aW9uOmNvbnRlbnRJZC01ODI5NDMzMTEiOlsicmVhZCJdfSwiZXhwIjoxNjAzNDAyMzk0LCJuYmYiOjE2MDMzOTk0NTR9.E_Zk4PcLcM43qMtvjdzOCdyAGQkESGozNhfj4ouy-V4',
    importedFile: '' as any,
    dropzoneConfig: {
      maxFiles: 1,
      acceptedFiles: '.xls,.xlsx',
    },
  }),

  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),

    registerImportedUsers() {
      if (this.importedFile) {
        this.$emit('user:imported', this.importedFile);
        return;
      }

      this.warningToast({
        text: this.$t('modals.addCompanyModal.secondTab.dropzoneEmpty') as string,
      });
    },

    encodeImportedFile({ file }: Dropzone): void {
      ConvertFile.toBase64(file as File).then((base64) => (this.importedFile = base64));
    },
  },
});
