
import mixins from 'vue-typed-mixins';
import HeaderSection from '@/resources/users/sections/HeaderSection.vue';
import ContentSection from '@/resources/users/sections/ContentSection.vue';

export default mixins().extend({
  name: 'Users',

  components: {
    HeaderSection,
    ContentSection,
  },

  data: () => ({
    search: undefined as string | undefined,
    displayModal: false,
  }),

  methods: {

    showModal() {
      this.displayModal = true;
    },

    searchUser(search?: string) {
      this.search = search?.trim();
    },
  },
});
