
import mixins from 'vue-typed-mixins';
import NotificationMixin from '@/mixins/NotificationMixin';
import { debounce } from 'lodash';

export default mixins(NotificationMixin).extend({
  name: 'HeaderSection',

  data: () => ({
    search: '',
  }),

  watch: {
    search(search: string, old: string) {
      const { length } = search.toString().trim();
      const { length: oldLength } = old.toString().trim();

      if (oldLength === 2 && length === 1) {
        this.$emit('users:search', '');
      }

      if (length === 0 || length > 1) {
        this.$emit('users:search', search);
      }
    },
  },

  methods: {
    registerUsers() {
      this.$emit('users:register-users', true);
    },
    searchDebounce: debounce(
      // eslint-disable-next-line func-names
      async function (search) {
        // @ts-ignore
        this.search = search;
      },
      1000,
    ),
    handleSearch(search: string, oldSearch: string) {
      const searchLength = search.toString().trim().length;
      const oldSearchLength = oldSearch.toString().trim().length;

      if (oldSearchLength === 2 && searchLength === 1) {
        this.$emit('users:search', '');
      }

      if (searchLength === 0 || searchLength > 1) {
        this.$emit('users:search', search);
      }
    },
  },
});
