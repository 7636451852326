
import { showDateToUser } from '@roit/roit-date';
import { PropOptions } from 'vue';
import mixins from 'vue-typed-mixins';
import { mapGetters, mapMutations } from 'vuex';
import NotificationMixin from '@/mixins/NotificationMixin';
import HubSpotService from '@/common/services/HubSpotService';
import UserService, { UserCreate, UserUpdate } from '@/common/services/UserService';
import {
  ManageUserForm,
  SelectOption,
  VForm,
} from '@/types';
import locales from '../locales';

const propFields: ManageUserForm = {
  firstName: '',
  lastName: '',
  job: '',
  email: '',
  profiles: {},
  responsabilities: [],
  allowedAccessTypes: [],
};

export default mixins(NotificationMixin).extend({
  name: 'UserForm',

  i18n: {
    messages: locales,
  },

  props: {
    value: {
      type: Object as () => ManageUserForm,
      required: false,
      default: () => ({}),
    } as PropOptions,
  },

  data: () => ({
    fields: { ...propFields } as ManageUserForm,
    profiles: [] as SelectOption[],
    selectedProfiles: [] as string[],
    responsibilities: [] as SelectOption[],
    occupations: [] as SelectOption[],
    selectedOccupation: {} as SelectOption,
  }),

  computed: {
    ...mapGetters({
      token: 'auth/token',
      userLoggedIn: 'auth/userLoggedIn',
    }),

    isDisabled(): boolean {
      return this.value?.id !== undefined;
    },

    isUpdate(): boolean {
      return this.isDisabled;
    },

    accessTypes(): Array<{ label: string; value: string }> {
      return [
        { label: 'Login e Senha', value: 'LOGIN_PASS' },
        { label: 'SSO', value: 'SSO' },
      ];
    },
  },

  watch: {
    fields: {
      deep: true,
      handler(user: ManageUserForm) {
        this.$emit('input', { ...this.value, ...user });
      },
    },
    occupations(occupations: SelectOption[]): void {
      if (this.isUpdate) {
        const [occupation] = occupations.filter(
          (occu: SelectOption) => occu.optionValue === this.fields.job,
        );
        this.selectedOccupation = occupation;
      }
    },
    profiles(profiles: SelectOption[]): void {
      const data = this.value as ManageUserForm;
      if (this.isUpdate) {
        this.selectedProfiles = profiles
          .filter(
            (opt: SelectOption) => {
              const foundProfile = Object.values(data?.profiles || {})
                .find((profile) => {
                  if (typeof profile === 'string') {
                    return profile === opt.optionValue;
                  }
                  return profile.id === opt.optionValue;
                });

              return foundProfile;
            },
          )
          .map((profile) => {
            if (typeof profile === 'string') {
              return profile;
            }
            return profile.optionValue;
          });
      }
    },
  },

  async mounted() {
    this.handleLoading(true);
    const promises = [
      this.getOccupations(),
      this.getProfiles(),
    ];
    await Promise.all(promises);

    this.getResponsibilities();
    this.userFieldsAdapter();
    this.handleLoading(false);
  },

  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),

    formatDateToUserView(date: string): string {
      try {
        return showDateToUser(date) || '';
      } catch {
        return '';
      }
    },

    userFieldsAdapter() {
      const { value: user } = this;
      if (this.isUpdate) {
        if (user.job) {
          const occupation = this.occupations.find((item) => item.optionValue === user.job);

          if (!occupation) {
            this.occupations.push({
              optionValue: user.job,
              optionLabel: user.job,
            });
          }
        }

        this.fields = {
          ...user,
        };
      }
    },

    mapProfile(profiles: any[]) {
      const output = profiles.map(
        (profile) => ({
          optionLabel: profile.name,
          optionValue: profile.id,
        } as SelectOption),
      );

      return output;
    },

    async getProfiles(): Promise<void> {
      try {
        this.profiles = this.mapProfile(
          (await UserService.findProfiles()).data.data,
        );
      } catch (e) {
        this.errorToast({
          text: this.$t('users.list.products.msgError') as string,
        });
      }
    },

    async getResponsibilities(): Promise<void> {
      try {
        const { data: response } = await HubSpotService.getResponsibilityArea();
        this.responsibilities = response.data.map(
          (item: {label: string; value: string}) => ({
            optionLabel: item.label,
            optionValue: item.value,
          } as SelectOption),
        );
      } catch (e) {
        this.errorToast({
          text: this.$t('users.list.responsibilityArea.msgError') as string,
        });
      }
    },

    async getOccupations(): Promise<void> {
      try {
        const { data: response } = await UserService.getOccupations();
        this.occupations = response.data.map(
          (item: { label: string; value: string }) => ({
            optionLabel: item.label,
            optionValue: item.value,
          } as SelectOption),
        );
      } catch (e) {
        this.errorToast({
          text: this.$t('users.list.occupations.msgError') as string,
        });
      }
    },

    profileDataToSave() {
      return this.selectedProfiles
        .map(
          (selectedProfile) => {
            const foundProfile = this.profiles
              .find((profile) => profile.optionValue === selectedProfile);
            return foundProfile && { id: foundProfile.optionValue, name: foundProfile.optionLabel };
          },
        )
        .filter((profile) => profile !== undefined) as {id: string; name: string}[];
    },

    async registerUser(): Promise<UserCreate> {
      const form: any = this.$refs.form as VForm;

      const valid = await form.validate();
      if (!valid) {
        throw new Error('invalid_form');
      }

      const payload = {
        ...this.fields,
        profiles: this.profileDataToSave(),
      };

      return payload;
    },

    async updateUser(): Promise<UserUpdate> {
      const form: any = this.$refs.form as VForm;

      const valid = await form.validate();
      if (!valid) {
        throw new Error('invalid_form');
      }

      const payload = {
        id: this.fields.iamId,
        firstName: this.fields.firstName,
        lastName: this.fields.lastName,
        job: this.fields.job,
        email: this.fields.email,
        profiles: this.profileDataToSave(),
        responsabilities: this.fields.responsabilities,
        allowedAccessTypes: this.fields.allowedAccessTypes,
      };

      return payload;
    },
  },
});
