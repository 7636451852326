var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "Dialog",
        {
          style: { maxHeight: "600px", minWidth: "600px" },
          attrs: { visible: _vm.displayModal, modal: true },
          on: {
            "update:visible": function ($event) {
              _vm.displayModal = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_vm._v(" " + _vm._s(_vm.titleHeader) + " ")]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("Button", {
                    ref: "btnClose",
                    staticClass: "outlined",
                    attrs: { label: _vm.$t("cancel") },
                    on: {
                      click: function ($event) {
                        _vm.displayModal = false
                      },
                    },
                  }),
                  !_vm.isUpdated
                    ? _c("Button", {
                        ref: "btnSubmit",
                        staticClass: "p-button-raised mr-0",
                        attrs: { label: _vm.$t("register"), autofocus: "" },
                        on: {
                          click: function ($event) {
                            return _vm.registerUser()
                          },
                        },
                      })
                    : _vm.isUpdated
                    ? _c("Button", {
                        ref: "btnSubmit",
                        staticClass: "p-button-raised mr-0",
                        attrs: { label: _vm.$t("update"), autofocus: "" },
                        on: {
                          click: function ($event) {
                            return _vm.updateUser()
                          },
                        },
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "py-3" },
            [
              _c("UserForm", {
                ref: "userForm",
                model: {
                  value: _vm.userData,
                  callback: function ($$v) {
                    _vm.userData = $$v
                  },
                  expression: "userData",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }