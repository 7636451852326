var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("p", [
      _vm._v(
        " " + _vm._s(_vm.$t("users.index.addUserModal.importMessage")) + " "
      ),
    ]),
    _c("div", { staticClass: "p-fluid p-formgrid p-grid p-ai-end" }, [
      _c("div", { staticClass: "p-field p-col-12 p-md-6 p-field" }, [
        _c("img", {
          staticClass: "p-mr-1",
          attrs: {
            src: require("@/common/assets/icons/excel.svg"),
            alt: "excel_icon",
          },
        }),
        _c(
          "a",
          {
            staticClass: "template-link",
            attrs: { href: _vm.urlTemplate, target: "_blank" },
          },
          [_vm._v(" Template.xlsx ")]
        ),
      ]),
      _c(
        "div",
        { staticClass: "p-field p-col-12 p-md-12" },
        [
          _c("upload-dropzone", {
            ref: "dropZone",
            attrs: {
              config: _vm.dropzoneConfig,
              description: _vm.$t("modals.addCompanyModal.secondTab.dropzone"),
            },
            on: {
              "dropzone:sending": function ($event) {
                return _vm.encodeImportedFile($event)
              },
              "dropzone:removed": function ($event) {
                _vm.importedFile = ""
              },
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }