
import Table from '@/common/components/table/TableInactivate.vue';
import UserService from '@/common/services/UserService';
import NotificationMixin from '@/mixins/NotificationMixin';
import {
  ManageUser,
  SelectOption,
} from '@/types';
import mixins from 'vue-typed-mixins';
import { mapGetters, mapMutations } from 'vuex';
import locales from '../locales';

export default mixins(NotificationMixin).extend({
  name: 'EnableUserModal',

  i18n: {
    messages: locales,
  },

  components: {
    CustomTable: Table,
  },

  props: {
    users: {
      type: Array,
      required: true,
    },

    control: {
      type: Boolean as () => boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedProfiles: [] as string[],
      profiles: [] as SelectOption[],
      data: [] as Array<string>,
      user: {} as ManageUser,
    };
  },

  computed: {
    ...mapGetters({
      token: 'auth/token',
      userLoggedIn: 'auth/userLoggedIn',
    }),

    displayEnableModal: {
      get(): boolean {
        return this.control;
      },
      set(value: boolean) {
        this.$emit('update:control', value);
      },
    },

    titleHeader(): string {
      return this.$t('users.index.actions.enable.one.title') as string;
    },

    title(): string {
      return this.$t('users.index.actions.enable.one.message') as string;
    },

    usersData(): Array<ManageUser> {
      return this.users as Array<ManageUser>;
    },

    userName(): string {
      return this.usersData && this.usersData.length > 0 ? this.usersData[0].name : '';
    },
  },

  watch: {
    data(data): void {
      this.data = data;
    },
    profiles(profiles: SelectOption[]): void {
      this.selectedProfiles = profiles
        .filter(
          (opt: SelectOption) => {
            const foundProfile = Object.values(this.usersData[0]?.profiles || {})
              .find((profile) => {
                if (typeof profile === 'string') {
                  return profile === opt.optionValue;
                }
                return profile.id === opt.optionValue;
              });

            return foundProfile;
          },
        )
        .map((profile) => {
          if (typeof profile === 'string') {
            return profile;
          }
          return profile.optionValue;
        });
    },
  },

  async mounted() {
    this.handleLoading(true);
    const promises = [
      this.getProfiles(),
    ];
    await Promise.all(promises);
    this.handleLoading(false);
  },

  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),

    mapProfile(profiles: any[]) {
      const output = profiles.map(
        (profile) => ({
          optionLabel: profile?.name,
          optionValue: profile.id,
        } as SelectOption),
      );

      return output;
    },

    async getProfiles(): Promise<void> {
      try {
        const profiles = (await UserService.findProfiles()).data.data;
        this.profiles = this.mapProfile(
          profiles,
        );
      } catch (e) {
        this.errorToast({
          text: this.$t('users.list.products.msgError') as string,
        });
      }
    },

    profileDataToSave() {
      return this.selectedProfiles
        .map(
          (selectedProfile) => {
            const foundProfile = this.profiles
              .find((profile) => profile.optionValue === selectedProfile);
            return foundProfile && { id: foundProfile.optionValue, name: foundProfile.optionLabel };
          },
        )
        .filter((profile) => profile !== undefined) as {id: string; name: string}[];
    },

    async enableUser(): Promise<void> {
      const { email } = this.usersData[0];
      const profiles = this.profileDataToSave();

      this.$emit('user:enable', { email, profiles });
      this.displayEnableModal = false;
    },
  },
});
