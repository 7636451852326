
import { PropOptions } from 'vue';
import Table from '@/common/components/table/TableInactivate.vue';
import dateFormat from '@/common/filters/DateFormat';
import { HeaderCustomTable, ManageUser } from '@/types';
import StatusColumn from '@/resources/users/components/StatusColumn.vue';
import AuthService from '@/common/services/AuthService';
import mixins from 'vue-typed-mixins';
import NotificationMixin from '@/mixins/NotificationMixin';
import locales from '../locales';

type Profiles = { [key: string]: { id: string; name: string } };

export default mixins(NotificationMixin).extend({
  name: 'UsersTable',

  i18n: {
    messages: locales,
  },

  components: {
    CustomTable: Table,
    StatusColumn,
  },

  filters: {
    dateFormat,
  },

  props: {
    rows: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean as () => boolean,
      default: false,
    },

    deselectAll: {
      type: Boolean as () => boolean,
      required: false,
      default: false,
    },

    allowedAccessTypes: {
      type: Array as () => Array<{ label: string; value: string }>,
      default: () => [],
    } as PropOptions,
  },

  data() {
    return {
      allItemsSelected: false as boolean,
      selectedItems: [] as Array<string | null>,
      visibleDeleteAll: false as boolean,
      header: {
        alias: [
          'checkbox',
          this.$t('users.usersTable.user'),
          this.$t('users.usersTable.occupation'),
          this.$t('users.usersTable.mail'),
          this.$t('users.usersTable.profile'),
          this.$t('users.usersTable.allowedAccessTypes'),
          this.$t('users.usersTable.status'),
        ],
        columnsToShow: [
          'checkbox',
          'fullName',
          'job',
          'email',
          'profiles',
          'allowedAccessTypes',
          'status',
        ],
      } as HeaderCustomTable,
      modalTitle: this.$t('users.index.profilesModal.title'),
    };
  },

  computed: {
    rowsData(): Array<ManageUser> {
      return this.rows as Array<ManageUser>;
    },
  },

  watch: {
    allItemsSelected(value: boolean): void {
      if (value) {
        this.selectedItems = this.rowsData.map(
          (user: ManageUser) => user.email,
        );
      } else {
        this.selectedItems = [];
      }
    },

    selectedItems(value: Array<string>): void {
      this.visibleDeleteAll = !!value.length;
    },

    deselectAll(value: boolean): void {
      if (value) {
        this.clearSelectedItems();
        this.$emit('user:deselect-all', false);
      }
    },
  },

  methods: {
    clearSelectedItems(): void {
      this.allItemsSelected = false;
      this.selectedItems = [];
    },

    editUser(user: ManageUser): void {
      this.$emit('user:update', user);
    },

    disableUsers(user?: ManageUser): void {
      this.$emit(
        'user:disable',
        user ? [user.email] : this.selectedItems,
      );
    },

    enableUsers(user?: ManageUser): void {
      this.$emit(
        'user:enable',
        user ? [user.email] : this.selectedItems,
      );
    },

    isDisabledUser(user: ManageUser): boolean {
      return user?.status === 'DISABLED';
    },

    status(user: ManageUser): string {
      return user?.status ?? 'ENABLED';
    },

    hasProfile(profiles: Profiles): boolean {
      return profiles && Object.values(profiles).length > 0;
    },

    toProfileList(profiles: Profiles) {
      const profileList: { id: string; name: string }[] = [];
      if (profiles) {
        Object.values(profiles).forEach((profile) => {
          profileList.push(profile);
        });
      }
      return profileList;
    },
    profileValues(profiles: Profiles) {
      const profileList = this.toProfileList(profiles);

      return profileList.map((profile) => profile.id);
    },
    async redefineMfa(iamId: string) {
      const service = new AuthService();
      try {
        await service.redefineMfa(iamId);
        this.successToast({
          text: this.$t('users.usersTable.redefineMfa.success') as string,
        });
      } catch {
        this.errorToast({
          text: this.$t('users.usersTable.redefineMfa.fail') as string,
        });
      }
    },
    handleRedefineMfa(event: Event, user: ManageUser) {
      const subTitle = this.$t('users.usersTable.redefineMfa.subTitle');
      const description = this.$t('users.usersTable.redefineMfa.description');

      (this.$confirm as any).require({
        target: event.currentTarget,
        message: `${subTitle} ${user.email}\n\n ${description} ${this.$t('interrogation')}`,
        header: this.$t('users.usersTable.redefineMfa.title'),
        acceptClass: 'p-button-danger users-table',
        rejectClass: 'p-button-danger p-button-outlined users-table',
        acceptLabel: this.$t('redefine'),
        rejectLabel: this.$t('cancel'),
        accept: async () => {
          await this.redefineMfa(user.iamId);
        },
      });
    },
  },
});
