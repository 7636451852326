import axios from '@/common/http';

class HubSpotService {
    private service: typeof axios = axios;

    private baseURL = `${process.env.VUE_APP_HUBSPOT_URL}/api/v1/contact`;

    public getResponsibilityArea() {
      const baseUrl = `${this.baseURL}/props/responsibility-area`;
      return this.service.get(baseUrl);
    }
}

export default new HubSpotService();
