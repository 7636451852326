
import mixins from 'vue-typed-mixins';
import { PropOptions } from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import NotificationMixin from '@/mixins/NotificationMixin';
import {
  ManageUserForm,
} from '@/types';
import locales from '../locales';
import UserImport from './UserImport.vue';
import UserForm from './UserForm.vue';

type UserFormRef = InstanceType<typeof UserForm>;

export default mixins(NotificationMixin).extend({
  name: 'UserFormModal',

  i18n: {
    messages: locales,
  },

  components: {
    UserForm,
    UserImport,
  },

  props: {
    control: {
      type: Boolean as () => boolean,
      default: false,
    },

    user: {
      type: Object as () => ManageUserForm,
      required: false,
      default: () => ({}),
    } as PropOptions,
  },

  data: () => ({
    urlTemplate:
      'https://api.media.atlassian.com/file/712e8294-98d8-4b7a-aab5-216266455e07/binary?client=41f70cfd-bff2-4c8a-9240-efc71ebe28ea&collection=contentId-582943311&dl=true&max-age=2592000&token=eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiI0MWY3MGNmZC1iZmYyLTRjOGEtOTI0MC1lZmM3MWViZTI4ZWEiLCJhY2Nlc3MiOnsidXJuOmZpbGVzdG9yZTpjb2xsZWN0aW9uOmNvbnRlbnRJZC01ODI5NDMzMTEiOlsicmVhZCJdfSwiZXhwIjoxNjAzNDAyMzk0LCJuYmYiOjE2MDMzOTk0NTR9.E_Zk4PcLcM43qMtvjdzOCdyAGQkESGozNhfj4ouy-V4',
    userForm: null as Vue | null,
    userData: {} as ManageUserForm,
  }),

  computed: {
    ...mapGetters({
      token: 'auth/token',
      userLoggedIn: 'auth/userLoggedIn',
    }),

    displayModal: {
      get(): boolean {
        return this.control;
      },
      set(value: boolean) {
        this.$emit('update:control', value);
      },
    },

    isUpdated(): boolean {
      return !!Object.keys(this.user).length;
    },

    titleHeader(): string {
      return (this.user?.id ? this.$t('users.userFormModal.titleUpdate') : this.$t('users.userFormModal.titleRegister')) as string;
    },
  },

  watch: {
    user(user): void {
      this.userData = user;
    },
  },

  async mounted() {
    this.userData = this.user;
  },

  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),

    async registerUser(): Promise<void> {
      const userForm = this.$refs.userForm as UserFormRef;
      if (userForm) {
        try {
          const payload = await userForm.registerUser();
          this.$emit('user:register', payload);
        } catch {
          // empty
        }
      }
    },

    async updateUser(): Promise<void> {
      const userForm = this.$refs.userForm as UserFormRef;
      if (userForm) {
        try {
          const payload = await userForm.updateUser();
          this.$emit('user:update', payload);
        } catch {
          // empty
        }
      }
    },
  },
});
