var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("ValidationObserver", { ref: "form", attrs: { slim: "" } }, [
    _c("div", { staticClass: "p-fluid p-formgrid p-grid" }, [
      _c(
        "div",
        { staticClass: "p-col-6 p-field" },
        [
          _c("input-validation", {
            attrs: {
              label: _vm.$t("users.userForm.fields.name.label"),
              name: _vm.$t("users.userForm.fields.name.label"),
              rules: "required|alpha_spaces",
              mode: "lazy",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("InputText", {
                      class: { "p-invalid": errors[0] },
                      attrs: {
                        id: "name",
                        placeholder: _vm.$t(
                          "users.userForm.fields.name.placeholder"
                        ),
                      },
                      model: {
                        value: _vm.fields.firstName,
                        callback: function ($$v) {
                          _vm.$set(_vm.fields, "firstName", $$v)
                        },
                        expression: "fields.firstName",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "p-col-6 p-field" },
        [
          _c("input-validation", {
            attrs: {
              label: _vm.$t("users.userForm.fields.surname.label"),
              name: _vm.$t("users.userForm.fields.surname.label"),
              rules: "required|alpha_spaces",
              mode: "lazy",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("InputText", {
                      class: { "p-invalid": errors[0] },
                      attrs: {
                        id: "surname",
                        placeholder: _vm.$t(
                          "users.userForm.fields.surname.placeholder"
                        ),
                      },
                      model: {
                        value: _vm.fields.lastName,
                        callback: function ($$v) {
                          _vm.$set(_vm.fields, "lastName", $$v)
                        },
                        expression: "fields.lastName",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "p-col-6 p-field" },
        [
          _c("input-validation", {
            attrs: {
              label: _vm.$t("users.userForm.fields.mail.label"),
              name: _vm.$t("users.userForm.fields.mail.label"),
              rules: "required|email",
              mode: "lazy",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("InputText", {
                      class: { "p-invalid": errors[0] },
                      attrs: {
                        id: "mail",
                        placeholder: _vm.$t(
                          "users.userForm.fields.mail.placeholder"
                        ),
                      },
                      model: {
                        value: _vm.fields.email,
                        callback: function ($$v) {
                          _vm.$set(_vm.fields, "email", $$v)
                        },
                        expression: "fields.email",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "p-col-12 p-md-6 p-field" },
        [
          _c("input-validation", {
            attrs: {
              label: _vm.$t("users.userForm.fields.occupation.label"),
              name: _vm.$t("users.userForm.fields.occupation.label"),
              rules: "required",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("Dropdown", {
                      class: { "p-invalid": errors[0] },
                      attrs: {
                        "input-id": "occupations",
                        options: _vm.occupations,
                        "option-label": "optionLabel",
                        "option-value": "optionValue",
                        placeholder: _vm.$t(
                          "users.userForm.fields.occupation.placeholder"
                        ),
                        filter: true,
                        "empty-filter-message": _vm.$t("noResultsFound"),
                      },
                      model: {
                        value: _vm.fields.job,
                        callback: function ($$v) {
                          _vm.$set(_vm.fields, "job", $$v)
                        },
                        expression: "fields.job",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "p-col-12 p-md-6 p-field" },
        [
          _c("input-validation", {
            attrs: {
              label: _vm.$t("users.userForm.fields.responsibilities.label"),
              name: _vm.$t("users.userForm.fields.responsibilities.label"),
              rules: "required",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("MultiSelect", {
                      class: { "p-invalid": errors[0] },
                      attrs: {
                        "input-id": "responsibilities",
                        options: _vm.responsibilities,
                        "option-label": "optionLabel",
                        "option-value": "optionValue",
                        placeholder: _vm.$t(
                          "users.userForm.fields.responsibilities.placeholder"
                        ),
                        display: "chip",
                      },
                      model: {
                        value: _vm.fields.responsabilities,
                        callback: function ($$v) {
                          _vm.$set(_vm.fields, "responsabilities", $$v)
                        },
                        expression: "fields.responsabilities",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "p-col-6 p-field" },
        [
          _c("input-validation", {
            attrs: {
              label: _vm.$t("users.userForm.fields.profile.label"),
              name: _vm.$t("users.userForm.fields.profile.label"),
              rules: "required",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("MultiSelect", {
                      class: { "p-invalid": errors[0] },
                      attrs: {
                        "input-id": "profile",
                        options: _vm.profiles,
                        "data-key": "",
                        "option-label": "optionLabel",
                        "option-value": "optionValue",
                        placeholder: _vm.$t(
                          "users.userForm.fields.profile.placeholder"
                        ),
                        display: "chip",
                      },
                      model: {
                        value: _vm.selectedProfiles,
                        callback: function ($$v) {
                          _vm.selectedProfiles = $$v
                        },
                        expression: "selectedProfiles",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "p-col-6 p-field" },
        [
          _c("input-validation", {
            attrs: {
              label: _vm.$t("users.userForm.fields.allowedAccessTypes.label"),
              vid: "allowedAccessTypes",
              rules: "required",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "p-formgroup-inline" },
                      _vm._l(_vm.accessTypes, function (accessType, i) {
                        return _c(
                          "div",
                          {
                            key: i,
                            staticClass: "p-field-checkbox mt-2.5 mb-0",
                          },
                          [
                            _c("Checkbox", {
                              ref: "allowedAccessTypes",
                              refInFor: true,
                              class: { "p-invalid": errors[0] },
                              attrs: {
                                id: accessType.label,
                                value: accessType.value,
                              },
                              model: {
                                value: _vm.fields.allowedAccessTypes,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.fields,
                                    "allowedAccessTypes",
                                    $$v
                                  )
                                },
                                expression: "fields.allowedAccessTypes",
                              },
                            }),
                            _c("label", { attrs: { for: accessType.label } }, [
                              _vm._v(_vm._s(accessType.label)),
                            ]),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }