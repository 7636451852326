
import mixins from 'vue-typed-mixins';
import { mapMutations } from 'vuex';
import axios from 'axios';
import UserService, { UserCreate, UserUpdate } from '@/common/services/UserService';
import PageCard from '@/common/template/PageCard.vue';
import UsersTable from '@/resources/users/components/UsersTable.vue';
import UserFormModal from '@/resources/users/components/UserFormModal.vue';
import NotificationMixin from '@/mixins/NotificationMixin';
import { ManageUser, DisableEmployee, Profile } from '@/types';
import EnableUserModal from '@/resources/users/components/EnableUserModal.vue';
import locales from '../locales';

export default mixins(NotificationMixin).extend({
  name: 'UserContentSection',

  i18n: {
    messages: locales,
  },

  components: {
    PageCard,
    UsersTable,
    UserFormModal,
    EnableUserModal,
  },

  props: {
    registerUser: {
      type: Boolean,
      default: false,
    },
    enable: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    emails: [] as Array<string>,
    usersToEnable: [] as Array<ManageUser>,
    user: {} as ManageUser,
    modalKey: '',
    filteredTable: [] as Array<any>,
    users: [] as Array<ManageUser>,
    loadingTable: true as boolean,
    deselectAllTable: false as boolean,
    displayModal: false,
    displayEnableModal: false,
  }),

  computed: {
    isShowModal: {
      get(): boolean {
        return this.displayModal;
      },
      set(val: boolean) {
        this.displayModal = val;
      },
    },
    isShowEnableModal: {
      get(): boolean {
        return this.displayEnableModal;
      },
      set(val: boolean) {
        this.displayEnableModal = val;
      },
    },
    accessTypes(): Array<{label: string; value: string}> {
      return [{
        label: this.$t('users.accessTypes.loginPass.label') as string,
        value: 'LOGIN_PASS',
      }, {
        label: this.$t('users.accessTypes.sso.label') as string,
        value: 'SSO',
      }];
    },
  },

  watch: {
    displayModal(displayModal: boolean): void {
      if (!displayModal) {
        this.user = {} as ManageUser;
      }
      if (this.registerUser && !displayModal) {
        this.$emit('update:registerUser', false);
      }
      if (this.enable && !displayModal) {
        this.$emit('update:enable', false);
      }
    },
    registerUser(registerUser: boolean) {
      if (registerUser) {
        this.showModal();
      }
    },
    enableUser(enableUsers: boolean) {
      if (enableUsers) {
        this.showEnableModal();
      }
    },
    search(search: string) {
      this.getUsers(search);
    },
  },
  async mounted() {
    await this.getUsers();
  },
  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),

    showModal(): void {
      this.displayModal = true;
    },

    showEnableModal(): void {
      this.displayEnableModal = true;
    },

    hideEnableModal(): void {
      this.displayEnableModal = false;
    },

    hideModal(): void {
      this.displayModal = false;
    },

    async saveUser(user: UserCreate): Promise<void> {
      try {
        this.handleLoading(true);

        await UserService.createUser(user);

        this.hideModal();

        this.successToast({
          text: this.$t('users.index.actions.save.msgSuccess') as string,
        });
        await this.getUsers();
      } catch (error) {
        const { response } = error as any;

        if (response.data.message === 'CPF already exists') {
          this.errorToast({
            text: this.$t('users.index.actions.save.alreadyRegistered') as string,
          });
        } else {
          this.errorToast({
            text: this.$t('users.index.actions.save.msgError') as string,
          });
        }
      } finally {
        this.handleLoading(false);
      }
    },

    async updateUser(id: string, user: UserUpdate): Promise<void> {
      try {
        this.handleLoading(true);
        await UserService.updateUser(id, user);

        this.hideModal();

        this.successToast({
          text: this.$t('users.index.actions.update.msgSuccess') as string,
        });

        await this.getUsers();
      } catch (e) {
        this.errorToast({
          text: this.$t('users.index.actions.update.msgError') as string,
        });
      } finally {
        this.handleLoading(false);
      }
    },

    editUser(user: ManageUser): void {
      this.showModal();
      this.$nextTick(() => {
        this.user = { ...user };
      });
    },

    enableUserEvent(emails: Array<string>): void {
      this.showEnableModal();
      this.$nextTick(() => {
        this.emails = emails;
        this.usersToEnable = this.enableUserData(emails);
      });
    },

    enableUserData(emails: Array<string>): Array<any> {
      if (emails.length === 1) {
        const user = this.users.find((usr) => usr.email === emails[0]);

        if (!user) {
          return [];
        }

        return [user];
      }
      const payload = emails.map((item) => ({ email: item }));
      return [payload];
    },

    async importUser(file: string): Promise<void> {
      try {
        this.handleLoading(true);

        await UserService.importUsers(file);

        this.hideModal();

        this.successToast({
          text: this.$t('users.index.actions.importUser.msgSuccess') as string,
        });
      } catch (e) {
        this.errorToast({
          text: this.$t('users.index.actions.importUser.msgError') as string,
        });
      } finally {
        this.handleLoading(false);
      }
    },

    async disableUser(emails: Array<string>): Promise<void> {
      if (emails.length === 1) {
        const user = this.users.find((usr) => usr.email === emails[0]);

        if (!user) return;

        await this.disableOneUser(user);
      } else {
        const payload = emails.map((email) => ({ email }));
        await this.disableManyUsers(payload);
      }

      this.deselectAllTable = true;
    },

    async disableOneUser(user: ManageUser): Promise<void> {
      try {
        const { isConfirmed } = await this.confirmDisable({
          title: this.$t('users.index.actions.disable.one.title') as string,
          text: this.$t('users.index.actions.disable.one.message') as string,
          extraText: user.name,
        });

        if (!isConfirmed) return;

        this.handleLoading(true);

        const payload: DisableEmployee = {
          email: user.email as unknown as string,
        };

        await UserService.disableUser(payload);

        this.successToast({
          text: this.$t('users.index.actions.disable.one.msgSuccess') as string,
        });
        await this.getUsers();
      } catch (e) {
        this.errorToast({
          text: this.$t('users.index.actions.disable.one.msgError') as string,
        });
      } finally {
        this.handleLoading(false);
      }
    },

    async enableUser(user: { email: string; profiles: Profile[] }): Promise<void> {
      try {
        this.handleLoading(true);

        await UserService.enableUser(user);

        this.hideEnableModal();

        this.successToast({
          text: this.$t('users.index.actions.enable.one.msgSuccess') as string,
        });
        await this.getUsers();
      } catch (e) {
        this.errorToast({
          text: this.$t('users.index.actions.enable.one.msgError') as string,
        });
      } finally {
        this.handleLoading(false);
      }
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async disableManyUsers(payload: DisableEmployee[]): Promise<void> {
      try {
        const { isConfirmed } = await this.confirmEnable({
          title: this.$t('users.index.actions.enable.many.title') as string,
          text: this.$t('users.index.actions.enable.many.message') as string,
        });

        if (!isConfirmed) return;

        this.handleLoading(true);

        await UserService.disableUsersBatch(payload);

        this.successToast({
          text: this.$t('users.index.actions.enable.many.msgSuccess') as string,
        });
        await this.getUsers();
      } catch (e) {
        this.errorToast({
          text: this.$t('users.index.actions.enable.many.msgError') as string,
        });
      } finally {
        this.handleLoading(false);
      }
    },

    async getUsers(search?: string): Promise<void> {
      try {
        this.loadingTable = true;

        const { masterId, supplierId } = this.$store.state.auth.userCompany;
        const usersList: ManageUser[] = (
          await UserService.paginationUsersAndSearch(
            { master: masterId, supplier: supplierId },
            search,
          )
        ).data.data;

        this.users = usersList;
        this.loadingTable = false;
      } catch (e) {
        if (!axios.isCancel(e)) {
          this.errorToast({
            text: this.$t('users.index.actions.findUsers.msgError') as string,
          });
        }
      }
    },
  },
});
