var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("ConfirmDialog"),
      _c("custom-table", {
        attrs: {
          "columns-data": _vm.header,
          data: _vm.rowsData,
          loading: _vm.loading,
          paginate: _vm.rowsData && _vm.rowsData.length > 0,
          "row-length": _vm.rowsData.length,
          "delete-all": _vm.visibleDeleteAll,
        },
        on: {
          "delete-all:clear": function ($event) {
            return _vm.clearSelectedItems()
          },
          "delete-all:delete": function ($event) {
            return _vm.disableUsers()
          },
          "enable-all:enable": function ($event) {
            return _vm.enableUsers()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "heading",
            fn: function ({ column }) {
              return [
                column === "checkbox"
                  ? _c(
                      "th",
                      { staticClass: "th-checkbox-user" },
                      [
                        _c("Checkbox", {
                          ref: "allItemsSelected",
                          staticClass: "checkbox-user",
                          attrs: { binary: true },
                          model: {
                            value: _vm.allItemsSelected,
                            callback: function ($$v) {
                              _vm.allItemsSelected = $$v
                            },
                            expression: "allItemsSelected",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "columns",
            fn: function ({ item, column }) {
              return [
                column === "checkbox"
                  ? _c(
                      "td",
                      { staticClass: "td-checkbox-user" },
                      [
                        _c("Checkbox", {
                          ref: "itemSelected",
                          staticClass: "checkbox-user",
                          attrs: { value: item.email },
                          model: {
                            value: _vm.selectedItems,
                            callback: function ($$v) {
                              _vm.selectedItems = $$v
                            },
                            expression: "selectedItems",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                column === "fullName"
                  ? _c("td", [_vm._v(" " + _vm._s(item.name) + " ")])
                  : _vm._e(),
                column === "job"
                  ? _c("td", [_vm._v(" " + _vm._s(item.job) + " ")])
                  : _vm._e(),
                column === "email"
                  ? _c("td", [
                      _c(
                        "a",
                        {
                          staticClass: "p-link",
                          attrs: {
                            href: `mailto:${item.email}`,
                            target: "_blank",
                          },
                        },
                        [_vm._v(" " + _vm._s(item.email) + " ")]
                      ),
                    ])
                  : _vm._e(),
                column === "profiles"
                  ? _c("td", [
                      _vm.hasProfile(item.profiles)
                        ? _c(
                            "div",
                            { staticClass: "profile-multiselect-wrapping" },
                            [
                              _c("MultiSelect", {
                                staticClass: "profile-multiselect",
                                attrs: {
                                  value: _vm.profileValues(item.profiles),
                                  "input-id": item.email,
                                  options: _vm.toProfileList(item.profiles),
                                  placeholder: _vm.$t(
                                    "users.userForm.fields.responsibilities.placeholder"
                                  ),
                                  "empty-filter-message":
                                    _vm.$t("noResultsFound"),
                                  "option-label": "name",
                                  "option-value": "id",
                                  reposition: "",
                                  display: "chip",
                                  "append-to": "body",
                                  filter: "",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                column === "allowedAccessTypes"
                  ? _c("td", [
                      item.allowedAccessTypes
                        ? _c("div", [
                            _c(
                              "div",
                              { staticClass: "p-formgroup-inline" },
                              _vm._l(
                                _vm.allowedAccessTypes,
                                function (allowedAccessType, i) {
                                  return _c(
                                    "div",
                                    {
                                      key: i,
                                      staticClass:
                                        "p-field-checkbox p-m-0 p-p-1",
                                    },
                                    [
                                      _c("Checkbox", {
                                        style: { cursor: "auto" },
                                        attrs: {
                                          value: allowedAccessType.value,
                                          disabled: true,
                                        },
                                        model: {
                                          value: item.allowedAccessTypes,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "allowedAccessTypes",
                                              $$v
                                            )
                                          },
                                          expression: "item.allowedAccessTypes",
                                        },
                                      }),
                                      _c("label", [
                                        _vm._v(_vm._s(allowedAccessType.label)),
                                      ]),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                column === "status"
                  ? _c("td", [
                      _c(
                        "div",
                        [
                          _c("StatusColumn", {
                            attrs: {
                              row: ((item) => ({ status: _vm.status(item) }))(
                                item
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "actions",
            fn: function ({ item }) {
              return [
                _c("div", { staticClass: "actions" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.top",
                          value: _vm.$t("users.usersTable.redefineMfa.title"),
                          expression:
                            "$t('users.usersTable.redefineMfa.title')",
                          modifiers: { top: true },
                        },
                      ],
                      staticClass: "action",
                      class: { "cursor-default": _vm.isDisabledUser(item) },
                      on: {
                        click: function ($event) {
                          !_vm.isDisabledUser(item) &&
                            _vm.handleRedefineMfa($event, item)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "icon-r-key",
                        class: { disabled: _vm.isDisabledUser(item) },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.top",
                          value: _vm.$t("edit"),
                          expression: "$t('edit')",
                          modifiers: { top: true },
                        },
                      ],
                      staticClass: "action",
                      class: { "cursor-default": _vm.isDisabledUser(item) },
                      on: {
                        click: function ($event) {
                          !_vm.isDisabledUser(item) && _vm.editUser(item)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "icon-r-edit",
                        class: { disabled: _vm.isDisabledUser(item) },
                      }),
                    ]
                  ),
                  !item.status || item.status == "ENABLED"
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip.top",
                              value: _vm.$t("users.index.actions.disableUser"),
                              expression:
                                "$t('users.index.actions.disableUser')",
                              modifiers: { top: true },
                            },
                          ],
                          staticClass: "action",
                          class: { "cursor-default": _vm.isDisabledUser(item) },
                          on: {
                            click: function ($event) {
                              !_vm.isDisabledUser(item) &&
                                _vm.disableUsers(item)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "icon-r-user-remove",
                            class: { color__red: !_vm.isDisabledUser(item) },
                          }),
                        ]
                      )
                    : _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip.top",
                              value: _vm.$t("users.index.actions.enableUser"),
                              expression:
                                "$t('users.index.actions.enableUser')",
                              modifiers: { top: true },
                            },
                          ],
                          staticClass: "action",
                          class: { "cursor-default": !_vm.status(item) },
                          on: {
                            click: function ($event) {
                              _vm.status(item) && _vm.enableUsers(item)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "icon-r-user",
                            class: { color__primary: _vm.status(item) },
                          }),
                        ]
                      ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }