
import Vue from 'vue';

interface RowType {
  status: string;
}

export default Vue.extend({
  name: 'StatusColumn',
  props: {
    row: {
      type: Object as () => RowType,
      required: true,
    },
  },
  computed: {
    statusColor(): string {
      const colors = {
        enabled: 'enabled',
        disabled: 'disabled',
        verify: 'verify',
      };

      const status = this.row.status.toLowerCase();
      return colors[status as keyof typeof colors];
    },
    statusText(): string {
      const { status } = this.row;

      switch (status.toUpperCase()) {
        case 'ENABLED':
          return 'Ativo';
        case 'DISABLED':
          return 'Inativo';
        default:
          return 'verify';
      }
    },
  },
});
