var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("UsersTable", {
        attrs: {
          rows: _vm.users,
          loading: _vm.loadingTable,
          "deselect-all": _vm.deselectAllTable,
          "allowed-access-types": _vm.accessTypes,
        },
        on: {
          "user:update": function ($event) {
            return _vm.editUser($event)
          },
          "user:disable": function ($event) {
            return _vm.disableUser($event)
          },
          "user:enable": function ($event) {
            return _vm.enableUserEvent($event)
          },
          "user:deselect-all": function ($event) {
            _vm.deselectAllTable = $event
          },
        },
      }),
      _c("EnableUserModal", {
        attrs: { users: _vm.usersToEnable, control: _vm.isShowEnableModal },
        on: {
          "update:control": function ($event) {
            _vm.isShowEnableModal = $event
          },
          "user:enable": function ($event) {
            return _vm.enableUser($event)
          },
        },
      }),
      _c("UserFormModal", {
        attrs: { control: _vm.isShowModal, user: _vm.user },
        on: {
          "update:control": function ($event) {
            _vm.isShowModal = $event
          },
          "user:register": function ($event) {
            return _vm.saveUser($event)
          },
          "user:update": function ($event) {
            $event && _vm.updateUser($event.id, $event)
          },
          "user:imported": function ($event) {
            return _vm.importUser($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }